<template>
    <v-container class="pa-0">
        <v-row no-gutters>
            
            <v-menu :close-on-content-click="false" transition="slide-y-transition" offset-y bottom right>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" :rules="rules" v-bind="attrs" v-on="on" :label="label" prepend-icon="far fa-calendar-alt" readonly :required="required" :placeholder="placeholder" :loading="loading"></v-text-field>
                </template>
                <v-date-picker v-model="date" flat scrollable reactive></v-date-picker>
            </v-menu>
            
            <v-menu :close-on-content-click="false" transition="slide-y-transition" offset-y bottom right>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="time" :rules="rules" v-bind="attrs" v-on="on" readonly :required="required" :placeholder="placeholder" :loading="loading"></v-text-field>
                </template>
                <v-time-picker v-model="time" format="24hr" flat scrollable use-seconds></v-time-picker>
            </v-menu>
            
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: [ "value", "label", "rules", "placeholder", "required", "loading" ],
        data () {
            return {
                date: this.value != null ? this.value.split(' ')[0] : new Date(Date.now()).toISOString().split('T')[0],
                time: this.value != null ? this.value.split(' ')[1] : new Date(Date.now()).toISOString().split('T')[1].split('.')[0],
            }
        },
        computed: {
            /*value: {
                // getter
                get: function () {
                    return `${this.date} ${this.time}`;
                },
                // setter
                set: function (value) {
                    console.log(`DateTimePicker: ${value}`);
                    if(this.date == null && this.time == null){
                        console.log('DateTimePicker value changed...');
                        console.log(value.split(' '));
                        var valueSplit = value.split(' ');
                        this.date = valueSplit[0];
                        this.time = valueSplit[1];
                    }
                }
            }*/
        },
        methods: {
            defaultValue(){
                if(this.value == null){
                    console.log('DateTimePicker set default value...');
                    console.log();
                    var valueSplit = new Date(Date.now()).toISOString().split('T');
                    this.date = valueSplit[0];
                    this.time = valueSplit[1].split('.')[0];
                    this.$emit('input', `${this.date} ${this.time}`);
                }
            }
        },
        watch: {
            value () {
                this.defaultValue();
            },
            date (value) {
                this.$emit('input', `${value} ${this.time}`);
            },
            time (value) {
                this.$emit('input', `${this.date} ${value}`);
            }
        },
        mounted () {
            console.log('DateTimePicker mounted...');
            this.defaultValue();

        }
    }
</script>

<style scoped>

</style>