// index.js
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import api from './plugins/api';
import auth from './plugins/auth';
import database from './plugins/database';

//components
import Header from './components/Header.vue';

import DateTimePicker from './components/DateTimePicker.vue';
import FilePicker from './components/FilePicker.vue';
import Image from './components/Image.vue';

export default {
    install: (app) => {
        //register plugins
        app.use(api);
        app.use(auth);
        app.use(database);

        //register components
        app.component('Header', Header);

        app.component('v-datetime-picker', DateTimePicker);
        app.component('axz-file-picker', FilePicker);
        app.component('axz-img', Image);
    }
  }