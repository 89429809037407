<template>
    <div class="footer">
        <v-container class="content" noselect>
            <slot />
            <v-container class="bottombar py-6" fluid>
                <v-row justify="center">
                    <span style="font-size: 0.75rem;">Copyright © 2007-{{year}} axXez®</span>
                </v-row>
                <v-row justify="center">
                    <span class="text-uppercase mt-2" style="font-size: 0.6rem;">all rights reserved</span>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>

<script>

export default {
    components: {
    },
    computed: {
        year () {
            return new Date().getFullYear();
        }
    }
}
</script>

<style>
.footer {
    background-color: var(--color-nav);
    /*height: 393px;*/
    /*border-bottom: 1px solid #020202;*/
}

.footer .bottombar {
    color: #DCDCDC;
    border-top: 1px solid #525252;
    /*height: 5.6rem;
    line-height: 5.6rem;*/
    /* padding-bottom: 34px; */
}

.footer .topbar {
    padding: 2.8rem 0px;
}

.footer .v-btn--tile:hover {
    color: var(--v-accent-base);
}
</style>