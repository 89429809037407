<template>
    <v-card class="box-image" :style="cardStyle" color="box" tile link :to="{ name: 'newsitem', params: { news_id: data.ID } }">
        <v-row no-gutters>
            <span class="text-h6 ml-2 my-2 font-weight-regular text-uppercase">{{ data.title }}</span>
            <span class="text-body-2 ml-2">{{ data.short }}</span>
            <span class="text-caption ml-2 font-weight-light">{{ UTCtoLocal(data.published) }}</span>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'box-image',
    props: [ 'data', 'to', 'link' ],
    data() {
        return {
            image: null
        }
    },
    computed: {
        cardStyle(){
            return { 
                backgroundImage: 'url(' + (this.image != null ? this.image : require('@/assets/news_dummy.png')) + ')',
                //backgroundBlendMode: this.$vuetify.breakpoint.smAndUp ? 'unset' : 'overlay'
            }
        }
    },
    mounted(){
        this.$db.files.loadImage(this.data.image).then((img) => {
            this.image = img;
        });
    }
    
}
</script>

<style scoped>
/* IMAGE BOX */
.box-image {
    background-position: left; /* maybe center !? */
    background-size: cover;
    background-repeat: no-repeat;
    /* margin: 0px 15px 15px 0px; */
    position: relative;
    height: 100%;
}

.box-image>.row {
    width: 100%;
    height: 132px;
    background-color: rgba(56, 56, 56, 0.64);
    position: absolute;
    bottom: 0;
}

.box-image>.row>span {
    display: block;
    width: 100%;
}
</style>