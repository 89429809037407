<template>
    <v-img :src="src" :contain="contain" :height="height" :width="width">
        <template>
            <v-row v-if="showloading != null && showloading && loading" justify="center" align="center">
                <!--v-progress-circular indeterminate color="accent" class="d-block ma-auto"></v-progress-circular-->
                <v-progress-linear indeterminate color="accent"></v-progress-linear>
            </v-row>
        </template>
    </v-img>
</template>

<script>
    export default {
        props: [ 'id', 'force', 'dummy', 'raw', 'contain', 'height', 'width', 'showloading' ],
        data(){
            return {
                image: null,
                loading: false,
            }
        },
        computed: {
            src(){
                return this.image != null ? this.image : this.raw != null ? this.raw : this.dummy != null ? require(`@/assets/${this.dummy}`) : null;
            }
        },
        methods: {
            load(){
                this.loading = true;
                console.log(`loading database image '${this.id}'`);
                this.$db.files.loadImage(this.id, this.force == true).then(img => {
                    this.loading = false;
                    this.image = img;
                });
            }
        },
        watch: {
            id(){
                this.load();
            }
        },
        mounted(){
            this.load();
        }
    }
</script>

<style scoped>

</style>