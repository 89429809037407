// plugins/api.js
export default {
    install: (app) => {
        var $auth = app.observable({
            initialized: false,
            loggedin: false,
            id: null,
            username: null,
            steamid: null,
            displayname: null,
            isAdmin: null,
            image: null,
            roles: [],
            canInvite: null,
            tokens: [],
            invitedUsers: [],
        })
        Object.defineProperty(app.prototype, '$auth', {
            get () { return $auth },
            set (value) { $auth = value }
        })

        app.prototype.$auth.hash = (data) => { //create base64 encoded sha2-512 hash
            var msgUint8 = new TextEncoder().encode(data);
            return crypto.subtle.digest('SHA-512', msgUint8).then(buffer => {
                var bytes = new Uint8Array(buffer);
                var binary = '';
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode( bytes[ i ] );
                }
                return btoa(binary);
            });           
        }

        app.prototype.$auth.status = () => {
            return app.prototype.call('auth', 'status').then(res => {
                //console.log("on status");
                //console.log(res);
                
                if(res.success){
                    //fill userData
                    $auth.id = res.data.ID;
                    $auth.username = res.data.username;
                    $auth.steamid = res.data.steamID;
                    $auth.displayname = res.data.displayname;
                    $auth.isAdmin = res.data.isAdmin;
                    $auth.image = res.data.image;
                    $auth.roles = res.data.roles;
                    $auth.canInvite = res.data.canInvite;

                    $auth.loggedin = true;
                    //console.log($auth);
                }
                return res;
            });
        }

        app.prototype.$auth.hasRole = (name) => {
            return $auth.roles.find(r => r.name == name) != null;
        }

        app.prototype.$auth.hasRoles = (roles) => {
            for ( var i in roles ) {
                if(!app.prototype.$auth.hasRole(roles[i]))
                    return false;
            }
            return true;
        }

        app.prototype.$auth.hasOneRole = (roles) => {
            for ( var i in roles ) {
                if(app.prototype.$auth.hasRole(roles[i]))
                    return true;
            }
            return false;
        }

        //db functions
        app.prototype.$auth.update = (displayname, image) => {
            return app.prototype.$db.get('auth', 'update', {
                displayname: displayname,
                image: image
            }).then(() => {
                return app.prototype.$auth.status();
            });
        }

        //token functions
        app.prototype.$auth.loadTokens = () => {
            return app.prototype.$db.get('auth', 'getTokens').then(data => $auth.tokens = data == null ? [] : data);
        }

        app.prototype.$auth.loadInvitedUsers = () => {
            return app.prototype.$db.get('auth', 'getInvitedUsers').then(data => $auth.invitedUsers = data == null ? [] : data);
        }

        app.prototype.$auth.createToken = (role) => {
            return app.prototype.$db.get('auth', 'createToken', { role: role });
        }

        app.prototype.$auth.renewToken = (token) => {
            return app.prototype.$db.get('auth', 'renewToken', { token: token });
        }

        app.prototype.$auth.deleteToken = (token) => {
            return app.prototype.$db.get('auth', 'deleteToken', { token: token });
        }

        $auth.status().then(() => {
            console.log("auth status finished");
            $auth.initialized = true;
            console.log("auth plugin initialized");
        });
    }
  }