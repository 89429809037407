import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';

Vue.use(VueRouter)

import axxez from '../../axxez-plugin/';
Vue.use(axxez);

//global components
import Grid from '@/generics/Grid.vue';
import GridItem from '@/generics/Grid-Item.vue';
import Headline from '@/generics/Headline.vue'
import Button from '@/generics/Button.vue';

Vue.component('Grid', Grid)
Vue.component('GridItem', GridItem)
Vue.component('Headline', Headline)
Vue.component('Button', Button)

import Tweet from '@/components/Tweet.vue';
Vue.component('Tweet', Tweet)

import Banner from '@/objects/Banner.vue'
import BoxText from '@/objects/Box-Text.vue'
import BoxInfo from '@/objects/Box-Info.vue'
import BoxImage from '@/objects/Box-Image.vue'

Vue.component('Banner', Banner)
Vue.component('BoxText', BoxText)
Vue.component('BoxInfo', BoxInfo)
Vue.component('BoxImage', BoxImage)

const routes = [
  {
    name: 'default',
    path: '*',
    redirect: '/home'
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/Home.vue'),
  },
  {
    name: 'news',
    path: '/news',
    component: () => import('@/views/News.vue'),
  },
  {
    name: 'newsitem',
    path: '/news/:news_id',
    props: true,
    component: () => import('@/views/NewsItem.vue'),
  },
  {
    name: 'teams',
    path: '/teams',
    props: true,
    component: () => import('@/views/Teams.vue'),
  },
  {
    name: 'teamItem',
    path: '/teams/:team_id',
    props: true,
    component: () => import('@/views/TeamItem.vue'),
  },
  {
    name: 'media',
    path: '/media',
    props: true,
    component: () => import('@/views/Media.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
