// plugins/database/shop.js
export default {
    install: (app, $db) => {
        $db.shop = {
            myorders: [],
            items: [],
            orders: [],
            status: [],
        }
    
        $db.shop.loadMyOrders = () => {
            return app.prototype.$db.get('shop', 'getMyOrders').then(data => $db.shop.myorders = data == null ? [] : data);
        }

        $db.shop.loadItems = () => {
            return app.prototype.$db.get('shop', 'getItems').then(data => $db.shop.items = data == null ? [] : data);
        }

        $db.shop.loadOrders = () => {
            return app.prototype.$db.get('shop', 'getAllOrders').then(data => $db.shop.orders = data == null ? [] : data);
        }

        $db.shop.loadStatus = () => {
            return app.prototype.$db.get('shop', 'getStatusList').then(data => $db.shop.status = data == null ? [] : data);
        }
        
        $db.shop.createOrder = (items) => {
            return app.prototype.$db.get('shop', 'createOrder', { items: items });
        }

        $db.shop.cancelOrder = (ID) => {
            return app.prototype.call('shop', 'cancelOrder', { ID: ID });
        }
       
        $db.shop.changeStatus = (ID, status) => {
            return app.prototype.$db.get('shop', 'changeStatus', {
                ID: ID,
                status: status
            });
        }

        $db.shop.createItem = (title, price, image) => {
            return app.prototype.$db.get('shop', 'createItem', {
                title: title,
                price: price,
                image: image,
            });
        }

        $db.shop.updateItem = (ID, title, price, image) => {
            return app.prototype.$db.get('shop', 'updateItem', {
                ID: ID,
                title: title,
                price: price,
                image: image,
            });
        }
    }
  }