<template>
    <v-flex>
        <v-card color="box" class="mb-4" tile>
            <v-card-subtitle>
                <v-icon small>fab fa-twitter</v-icon> <a href="https://twitter.com/axxezEU" target="_blank">@axxezEU</a> - {{new Date(tweet.created_at).toLocaleString('de-DE')}}
            </v-card-subtitle>
            <v-card-text>
                <span class="text-body-2" style="white-space: break-spaces;" v-html="parseText()" />
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    export default {
        props: [ "tweet" ],
        data() {
            return {

            }
        },
        methods: {
            parseText(){
                var parsedText = this.tweet.text;
                if(this.tweet.entities.mentions != null)
                    this.tweet.entities.mentions.forEach(mention => {
                        parsedText = parsedText.replace('@'+mention.username, `<a href="https://twitter.com/${mention.username}" target="_blank">@${mention.username}</a>`);
                    });

                if(this.tweet.entities.hashtags != null)
                    this.tweet.entities.hashtags.forEach(hashtag => {
                        parsedText = parsedText.replace('#'+hashtag.tag, `<a href="https://twitter.com/hashtag/${hashtag.tag}?src=hashtag_click" target="_blank">#${hashtag.tag}</a>`);
                    });
                
                if(this.tweet.entities.urls != null)
                    this.tweet.entities.urls.forEach(url => {
                        parsedText = parsedText.replace(url.url, `<a href="${url.expanded_url}" target="_blank">${url.display_url}</a>`);
                    });

                return parsedText;
            }
        }
    }
</script>

<style scoped>

</style>