// plugins/database/files.js
export default {
    install: (app, $db) => {
        $db.files = {
            data: [],
            images: {},
        }
    
        $db.files.load = () => {
            return app.prototype.$db.get('files', 'getAll').then(data => $db.files.data = data == null ? [] : data);
        }

        $db.files.get = (identifier) => {
            return app.prototype.$db.get('files', 'get', { identifier: identifier });
        }

        $db.files.getImage = (identifier) => {
            return app.prototype.$db.get('files', 'getImage', { identifier: identifier });
        }

        $db.files.loadImage = async (identifier, force=false) => {
            if(identifier == null){
                return null;
            }
            if(force || !(identifier in $db.files.images)){
                $db.files.images[identifier] = await app.prototype.$db.get('files', 'getImage', { identifier: identifier });
            }
            return $db.files.images[identifier];
        }

        $db.files.download = (identifier, filename) => {
            return `${app.prototype.api_url('files', 'download')}?identifier=${identifier}&filename=${filename}`;
        }

        $db.files.create = (data, internal=0) => {
            return app.prototype.$db.get('files', 'create', {
                internal: internal,
                data: data,
            }, true);
        }
        
        $db.files.update = (identifier, data, internal=0) => {
            return app.prototype.$db.get('files', 'update', {
                identifier: identifier,
                internal: internal,
                data: data,
            }, true);
        }

        $db.files.createOrUpdate = (identifier, data, internal=0) => {
            if(identifier == null){
                return $db.files.create(data, internal);
            }
            else {
                return $db.files.update(identifier, data, internal).then(() => identifier);
            }
        }
        
        $db.files.setInternal = (identifier, internal) => {
            return app.prototype.call('files', 'setInternal', {
                identifier: identifier,
                state: internal,
            });
        }
    }
  }