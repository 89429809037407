<template>
    <v-app>
        <Header :title="$vuetify.breakpoint.xs ? 'axxez community' : 'axxez esport community'" logo="axXez_Logo.svg" container="content">
            <template v-slot:subheader>
                <v-btn v-for="(item, i) in ($vuetify.breakpoint.smAndUp ? menu : menu.slice(0, 2))" :key="i" text tile v-bind="item.link" :disabled="item.disabled">
                    <v-icon class="d-flex d-md-none d-lg-flex" :left="$vuetify.breakpoint.lgAndUp" small>{{item.icon}}</v-icon>
                    <span class="d-none d-md-flex">{{item.text}}</span>
                </v-btn>
                <v-menu v-if="$vuetify.breakpoint.xs" transition="slide-y-transition" bottom left offset-y tile>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text tile v-bind="attrs" v-on="on">
                            <v-icon small>fas fa-bars</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="pa-0" color="menu" tile>
                        <v-list-item link dense v-for="(item, i) in menu.slice(2, menu.length)" :key="i" v-bind="item.link" :disabled="item.disabled">
                            <v-list-item-icon>
                                <v-icon small left>{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-uppercase">{{item.text}}</v-list-item-title>
                            </v-list-item-content>
                            
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </Header>

        <!--keep-alive-->
        <v-main>
            <v-progress-linear :active="$db.loading" :indeterminate="true" absolute top />
            <router-view />
        </v-main>
        <!--/keep-alive--> 

        <!-- FOOTER -->
        <Footer>
            <v-row no-gutters class="pt-8 pb-12">
                <v-col>
                    <v-row justify="start" align="center">
                        <v-col cols="auto">
                            <v-img src="@/assets/axXez_Logo.svg" width="54px" height="54px" contain class="mx-auto"  />
                        </v-col>
                        <v-col>
                            <span class="d-block text-body-2 text-uppercase">axXez</span>
                            <span class="d-block text-uppercase" style="font-size: 0.65rem;">eSport Community</span>
                        </v-col>
                        <v-col />
                    </v-row>
                </v-col>
                <v-col>
                    <span class="text-uppercase mt-5 mb-8 d-block" style="font-size: 0.7rem;">social</span>
                    <v-btn v-for="(item, i) in menu_social" :key="i" text tile plain x-small depressed style="font-size: 0.6rem;" v-bind="item.link">{{item.text}}</v-btn>
                </v-col>
                <v-col>
                    <span class="text-uppercase mt-5 mb-8 d-block" style="font-size: 0.7rem;">help</span>
                    <v-btn v-for="(item, i) in menu_help" :key="i" text tile plain x-small depressed style="font-size: 0.6rem;" v-bind="item.link">{{item.text}}</v-btn>
                </v-col>
            </v-row>
        </Footer>
        <!-- FOOTER END -->
        
        <v-snackbar bottom app color="rgba(0,0,0,0)" elevation="0" tile v-model="$api.error.show" :timeout="5000" >
            <v-card color="secondary">
                <v-alert dense text type="error">
                    <strong v-show="$api.error.source != null" class="text-uppercase">{{$api.error.source}}:</strong> {{$api.error.msg}}
                </v-alert>
            </v-card>
        </v-snackbar>


        <!-- COOKIE BANNER -->
        <v-bottom-sheet v-if="!cookiesAccepted" :value="!cookiesAccepted" persistent light>
            <v-sheet class="text-center">
                <v-container>
                    <p class="text-h6">
                        Diese Website verwendet Cookies
                    </p>
                    <p>
                        axXez.eu nutzt Cookies um einen Benutzerbereich zu realisieren.
                        Cookies werden <b>nicht</b> zum Tracking oder Vermarktung verwendet.
                    </p>
                    <p>
                        Mit dem Klick auf den Button "Cookies zulassen" erklären Sie sich mit der Verwendung von Cookies einverstanden.
                    </p>
                    <v-btn @click="acceptCookies()" color="green">Cookies zulassen</v-btn>
                    <v-row>
                        <v-col>
                            <v-btn @click="acceptCookies()" text small disabled>datenschutzerklärung</v-btn>
                            <v-divider vertical />
                            <v-btn @click="acceptCookies()" text small disabled>Impressum</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-bottom-sheet>

    </v-app>
</template>

<script>
// @ is an alias to /src
import Footer from '@/objects/Footer.vue';


export default {
    name: 'app',
    components: {
        Footer
    },
    data () {
        return {
            menu: [
                { text: "home", link: { to: "/home" }, icon: 'fas fa-home', disabled: false },
                { text: "news", link: { to: "/news" }, icon: 'fas fa-newspaper', disabled: false },
                { text: "about", link: { to: "/about" }, icon: 'fas fa-address-card', disabled: true },

                { text: "teams", link: { to: "/teams" }, icon: 'fas fa-users', disabled: false },
                { text: "streams", link: { to: "/streams" }, icon: 'fab fa-twitch', disabled: true },
                { text: "media", link: { to: "/media" }, icon: 'fas fa-photo-video', disabled: false },
                { text: "shop", link: { href: "https://www.manatee.gg/collections/axxez-eu", target: "_blank" }, icon: 'fas fa-shopping-cart', disabled: false },
                //{ text: "events", to: "/events", icon: 'far fa-calendar-alt', disabled: true },
            ],
            menu_social: [            
                { text: "twitter", link: { href: "https://twitter.com/axxezEU", target: "_blank" } },
                { text: "instagram", link: { href: "https://www.instagram.com/axxez.eu", target: "_blank" } },
                { text: "youtube", link: { href: "https://www.youtube.com/channel/UCyW_O5037fWZUVPvIb5wuWg", target: "_blank" } },
                { text: "twitch", link: { href: "https://www.twitch.tv/axxeztv", target: "_blank" } },

                { text: "teamspeak", link: { href: "ts3server://ts.axxez.de" } },
                { text: "discord", link: { href: "https://discord.gg/2AH7GZc", target: "_blank" } },
            ],
            menu_help: [
                { text: "sponsoren", link: { disabled: true } },
                { text: "kontakt", link: { disabled: true } },
                { text: "impressum", link: { disabled: true } },
                { text: "datenschutzerklärung", link: { disabled: true } },
            ]
        }
    },
    watch: {

    },
    computed: {
        cookiesAccepted () {
            return document.cookie.match(/cookiesAccepted=true;?/) != null;
        }
    },
    methods: {
        acceptCookies() {
            if(!this.cookiesAccepted){
                document.cookie = "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                this._computedWatchers.cookiesAccepted.evaluate();
                this.$forceUpdate();
            }
        },
        loadData: function(name) {
            fetch(`data/${name}.json`, { mode: 'same-origin' }).then(response => response.json())
            .then((data) => { this.jsonData[name] = data; /*console.log(this.jsonData);*/ });
        }
    },
    mounted() {
        //this.$api.debug = true;
    }
}
</script>

<style>
:root {
    --color-site: #2F2F2F;
    --color-site-font: #FFFFFF;
    --color-nav: #000000;
    --color-nav-topfont: #DCDCDC;
    --color-accent: #509cbf;
    
    --color-description: #A4A4A4;

    --color-box: #1D1D1D;
    --color-box-accent: #2C2C2C;

    --font-main: 62.5%; /* Default Chrome: 16px * 0.625 = 10px */

    --font-tiny: 1rem; /* 10px */
    --font-small: 1.2rem; /* 12px */
    --font-normal: 1.4rem; /* 14px */
    --font-big: 2rem; /* 20px */
    --font-head: 2.4rem; /* 24px */

    --site-max-size: 1320px; /* old 1320px */
}

html {
    /*font-size: var(--font-main) !important;*/
    font-variant: none;
    font-family: Roboto, Arial, sans-serif;
    /*font-weight: normal;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    
}

body {
    padding: 0px;
    margin: 0px;

    /*font-size: var(--font-normal);*/
}

.content {
    max-width: var(--site-max-size); 
    margin: 0px auto;
    /* text-align: center; */
}

#site {
    min-height: 100vh;
    color: var(--color-site-font); 
    background-color: var(--color-site);
}

#app {
    background-color: var(--v-background-base);
}
</style>