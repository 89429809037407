// plugins/database.js

import files from './database/files.js';
import media from './database/media.js';
import games from './database/games.js';
import news from './database/news.js';
import teams from './database/teams.js';
import users from './database/users.js';
import shop from './database/shop.js';

export default {
    install: (app) => {
        var $db = {
            loading: false,
            loadingQueue: [],
        };
        //console.log(app);
        //console.log(app.prototype);

        //load database functions
        files.install(app, $db);
        media.install(app, $db);
        games.install(app, $db);
        news.install(app, $db);
        teams.install(app, $db);
        users.install(app, $db);
        shop.install(app, $db);

        
        $db = window.$db = app.observable($db);
        Object.defineProperty(app.prototype, '$db', {
            get () { return $db },
            set (value) { $db = value }
        })

        $db.loadingQueueAdd = (key) =>{
            $db.loadingQueue.push(key);
            $db.loading = $db.loadingQueue.length > 0;
        }
        $db.loadingQueueRemove = (key) =>{
            var index = $db.loadingQueue.indexOf(key);
            //console.log(`Remove '${key}', Length: ${$db.loadingQueue.length}, Index: ${index}`);
            if(index > -1){
                $db.loadingQueue.splice(index, 1);
                $db.loading = $db.loadingQueue.length > 0;
            }
        }

        //app.prototype.$watch('$db.loadingQueue', () => $db.loading = $db.loadingQueue.length > 0 )
        app.prototype.$db.get = (_class, _function, _postData=null, formData=false) => {
            var callid = `${_class}/${_function}T${Date.now()}`;
            $db.loadingQueueAdd(callid);
            //console.log(`queue '${callid}'...`);
            return app.prototype.call(_class, _function, _postData, formData).then(res => {
                $db.loadingQueueRemove(callid);
                //console.log(`finished '${callid}'...`);
                if(res.success && res.data != null)
                    return res.data;
                else return null;
            });
        }

        console.log("database plugin loaded");
        console.log($db);
    }
  }