<template>
    <div class="box">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'box'
}
</script>

<style scoped>
    /* BOX */
    .box {
        background-color: var(--color-box);
    }

</style>