<template>
    <v-btn class="btn-main" v-bind="link" :disabled="disabled" rounded large :light="disabled">
        {{ text }}
    </v-btn>
</template>

<script>
    export default {
        name: 'Button',
        props: [ 'text', 'link', 'disabled' ],
        computed: {
            
        }
    }
</script>

<style scoped>
/* BTN MAIN */
.btn-main {
    border: 2px solid var(--v-accent-base);
    /*background-color: rgba(0,0,0,0) !important;*/
}

.btn-main.v-btn--disabled {
    border: 0;
}
</style>