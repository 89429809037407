export default {
  icons: {
    iconfont: 'fa',
  },
  theme: {
    /*disable: true,*/
    dark: true,
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      dark: {
        background: '#2F2F2F',
        primary: '#509cbf',
        secondary: '#2F2F2F',
        accent: '#509cbf',
        menu: '#000000',
        box: '#1D1D1D',
        box_accent: '#2C2C2C',
        /*error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'*/
      },
    },
  }
}
