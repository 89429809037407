<template>
    <Box class="mt-6">
        <Grid class="box-info">
            <span class="text-h6 font-weight-regular">{{title}}</span>
            <div v-for="(entry, i) in entries" :key="i">
                <v-icon color="accent">fas fa-chevron-right</v-icon>
                <span class="text-body-2">{{entry}}</span>
            </div>
        </Grid>
    </Box>
</template>

<script>
import Box from '@/objects/Box.vue';

export default {
    name: "box-info",
    props: [ 'title', 'entries' ],
    components: {
        Box
    }
    
}
</script>

<style scoped>
.box-info {
    
    padding: 18px 0px 18px 24px;
}

.box-info>span {
    margin-bottom: 12px;
}

.box-info>div {
    padding: 14px 14px;
    line-height: 14px;
}

.box-info>div i {
    font-size: 14px;
    color: var(--color-accent);
}

.box-info>div span {
    margin-left: 4px;
}
</style>