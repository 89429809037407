// plugins/database/users.js
export default {
    install: (app, $db) => {
        $db.users = {
            data: [],
        }
    
        $db.users.load = () => {
            return app.prototype.$db.get('users', 'getAll').then(data => $db.users.data = data == null ? [] : data);
        }

       
    }
  }