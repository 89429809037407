import { render, staticRenderFns } from "./DateTimePicker.vue?vue&type=template&id=8553912a&scoped=true&"
import script from "./DateTimePicker.vue?vue&type=script&lang=js&"
export * from "./DateTimePicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../axxez-frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8553912a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../axxez-frontend/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VContainer,VDatePicker,VMenu,VRow,VTextField,VTimePicker})
