<template>
    <v-row no-gutters class="headline mb-6" :id="anchor">
        <span class="text-h5 text-uppercase">{{ text }}</span>
    </v-row>
</template>

<script>
export default {
    name: 'Headline',
    props: [ 'text', 'anchor' ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headline {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: default;
}
</style>