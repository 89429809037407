// plugins/api.js
export default {
    install: (app) => {
        //error store (maybe not global in window? only vue?) //var $api = works =)
        window.$api = app.observable({
            error: {
                show: false,
                msg: null,
                source: null,
            },
            debug: false
        })
        Object.defineProperty(app.prototype, '$api', {
            get () { return window.$api },
            set (value) { window.$api = value }
        })

        //error functions
        app.prototype.error = (msg, source='api') => {
            window.$api.error.msg = msg;
            window.$api.error.source = source;
            window.$api.error.show = true;
        }

        app.prototype.api_url = (_class, _function) => {
            return `https://api.axxez.eu/${_class}/${_function}`;
        }

        //call api functions
        app.prototype.call = (_class, _function, _postData=null, formData=false) => {
            return app.prototype.callApi(_class, _function, _postData, formData, window.$api.debug);
        }
        app.prototype.callApi = (_class, _function, _postData=null, formData=false, showError=true) => {
            return new Promise((resolve) => {
                var options = { 
                    method: 'GET',
                    credentials: 'include',
                    cache: 'no-cache',
                    redirect: 'follow'
                };
                
                if(_postData){
                    options.method = 'POST';
                    if(formData){
                        options.body = new FormData();
                        for ( var key in _postData ) {
                            options.body.append(key, _postData[key]);
                        }
                    }
                    else {
                        options.body = JSON.stringify(_postData);
                        options.headers = {
                            'Content-Type': 'application/json;charset=UTF-8'
                        }
                    }
                }

                fetch(app.prototype.api_url(_class, _function), options).then(response => {
                    console.log(response);
                    if (!response.ok) {
                        const error = response.statusText;
                        return Promise.reject(error);
                    }
                    return response.json();
                }).then(res => {
                    if(showError && !res.success){
                        console.log("res success false");
                        app.prototype.error(res.msg);
                    }
                    resolve(res);
                }).catch(error => {
                    console.error("API", error);
                    app.prototype.error(`${error}`);

                    //return default api response on error
                    resolve({ success: false, msg: `${error}` });
                });
            });
        }

        app.prototype.UTC = (dateString) => {
            return new Date(dateString+' UTC');
        } 
        app.prototype.UTCtoLocal = (dateString, locale="de-DE") => {
            return new Date(dateString+' UTC').toLocaleString(locale);
        } 
        app.prototype.UTCtoLocalDate = (dateString, locale="de-DE") => {
            return new Date(dateString+' UTC').toLocaleDateString(locale);
        } 
        app.prototype.UTCtoLocalTime = (dateString, locale="de-DE") => {
            return new Date(dateString+' UTC').toLocaleTimeString(locale);
        } 

        //generic show popup function
        app.prototype.popup = (url) => {
            return new Promise((resolve, reject) => {
                var popup = window.open(url, '_blank', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no');
                if(popup != null) {
                    var popupTick = setInterval(function() {
                        if (popup.closed) {
                            clearInterval(popupTick);
                            resolve();
                        }
                    }, 250);
                }
                else reject();
            });
        }
    }
  }