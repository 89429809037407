// plugins/database/news.js
export default {
    install: (app, $db) => {
        $db.news = {
            data: [],
            top: [],
            toploaded: 0,
            latest: [],
            latestloaded: 0,
            latestTweets: [],
            latestTweetsloaded: 0,
        }
    
        $db.news.load = () => {
            return app.prototype.$db.get('news', 'getAll').then(data => $db.news.data = data == null ? [] : data);
        }

        $db.news.loadTop = () => {
            if($db.news.toploaded < Date.now() - 1800000){ //30 min
                $db.news.toploaded = Date.now();
                return app.prototype.$db.get('news', 'getTopNews').then(data => $db.news.top = data == null ? [] : data);
            }
        }

        $db.news.loadLatest = () => {
            if($db.news.latestloaded < Date.now() - 1800000){ //30 min
                $db.news.latestloaded = Date.now();
                return app.prototype.$db.get('news', 'getLatestNews').then(data => $db.news.latest = data == null ? [] : data);
            }
        }

        $db.news.loadLatestTweets = () => {
            if($db.news.latestTweetsloaded < Date.now() - 1800000){ //30 min
                $db.news.latestTweetsloaded = Date.now();
                return app.prototype.$db.get('news', 'getLatestTweets').then(data => $db.news.latestTweets = data == null ? [] : data);
            }
        }

        $db.news.get = (ID) => {
            return app.prototype.$db.get('news', 'get', { ID: ID });
        }

        $db.news.create = (title, short, text, published, image) => {
            return app.prototype.$db.get('news', 'create', {
                title: title,
                short: short,
                text: text,
                published: published,
                image: image,
            });
        }

        $db.news.update = (ID, title, short, text, published, image) => {
            return app.prototype.$db.get('news', 'update', {
                ID: ID,
                title: title,
                short: short,
                text: text,
                published: published,
                image: image,
            });
        }

        $db.news.setOnline = (ID, state) => {
            return app.prototype.call('news', 'setOnline', {
                ID: ID,
                state: state,
            });
        }

        $db.news.setInternal = (ID, state) => {
            return app.prototype.call('news', 'setInternal', {
                ID: ID,
                state: state,
            });
        }
    }
  }