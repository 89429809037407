<template>
    <v-container fluid class="pr-6">
        <v-row v-if="title != null">
            <v-col>
                <span class="text-h6 mb-4 primary--text">{{title}}</span>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="text != null">
            <v-col>
                <p style="overflow: hidden !important; text-overflow: ellipsis !important; white-space: break-spaces;" class="text-body-2">{{text}}</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: "box-text",
    props: [ 'title', 'text' ],
    components: {

    }
}
</script>