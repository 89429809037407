<template>
    <div :style="styleObject">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Grid',
    props: [ 'col', 'row' ],
    computed: {
        styleObject: function () {
            return {
                'display': 'grid',
                'grid-template-columns': this.col,
                'grid-template-rows': this.row
            }
        }
    }
}
</script>
