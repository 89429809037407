<template>
    <v-app-bar app clipped-left clipped-right flat floating tile height="110px" class="header" m-bottom-big>
        <div id="bar" :class="container">
            <v-row no-gutters class="topbar">
                <v-col>
                    <span v-if="title != null" class="text-md-overline text-caption text-uppercase ml-2">{{title}}</span>
                </v-col>
                <v-col class="d-none d-sm-block">
                    <v-row no-gutters justify="center" class="mt-1">
                        <v-btn v-for="(item, i) in iconMenu" :key="i" x-small fab text v-bind="item.link"><v-icon>{{item.icon}}</v-icon></v-btn>
                    </v-row>
                </v-col>
                <v-col cols="auto" sm="4">
                    <v-row no-gutters justify="end" class="loginbar" v-if="!$auth.loggedin">
                        <v-menu transition="slide-y-transition" bottom left offset-y tile v-model="signupForm.show" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small text tile v-bind="attrs" v-on="on"><span class="d-none d-sm-flex">SIGN UP</span><v-icon class="d-flex d-sm-none" small>fas fa-file-signature</v-icon></v-btn>
                            </template>
                            <v-card tile color="secondary" min-width="340px">
                                <v-container>
                                    <v-form ref="tokenForm" class="mt-2" v-model="tokenForm.valid" v-on:submit.prevent="() => { }">
                                        <v-row dense>
                                            <v-text-field dense v-model="signupForm.token" :rules="validate.token" label="Token" placeholder=" " required :error="tokenForm.error" :loading="tokenForm.loading" :disabled="tokenForm.loading || signupForm.tokenValid" />
                                        </v-row>
                                    </v-form>
                                    <v-row dense>
                                        <v-col v-show="signupForm.tokenValid" class="text-overline text-center">sign up with steam</v-col>
                                    </v-row>
                                    <v-row justify="center" class="mb-2">
                                        <button v-show="signupForm.tokenValid" :disabled="signupForm.loading" @click="signupSteam()"><img src="../assets/signinthroughsteam.png" /></button>
                                    </v-row>
                                    <v-divider v-show="signupForm.tokenValid" />
                                    <v-row dense class="mb-2">
                                        <v-col v-show="signupForm.tokenValid" class="text-overline text-center">or sign up with<br>username & password</v-col>
                                    </v-row>
                                    <v-form v-show="signupForm.tokenValid" ref="signupForm" v-model="signupForm.valid" v-on:submit.prevent="() => { }">                           
                                        <v-row dense>
                                            <v-text-field dense v-model="signupForm.username" :rules="validate.username" label="Username" placeholder=" " required :error="signupForm.error" :loading="signupForm.loading" :disabled="signupForm.loading" />
                                        </v-row>
                                        <v-row dense>
                                            <v-text-field dense v-model="signupForm.password" :rules="validate.password" label="Password" placeholder=" " required :error="signupForm.error" :loading="signupForm.loading" :disabled="signupForm.loading" type="password" @change="signupForm.password2 = null"/>
                                        </v-row>
                                        <v-row dense>
                                            <v-text-field dense v-model="signupForm.password2" :rules="validate.password2" label="Repeat password" placeholder=" " required :error="signupForm.error" :loading="signupForm.loading" :disabled="signupForm.loading" type="password" />
                                        </v-row>
                                        <v-btn dense color="accent" type="submit" tile block class="text-capitalize" :disabled="!signupForm.valid" :loading="signupForm.loading" @click="signup()">Sign Up</v-btn>
                                    </v-form>
                                </v-container>
                            </v-card>
                        </v-menu>
                        <v-menu transition="slide-y-transition" bottom left offset-y tile v-model="loginForm.show" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small text tile v-bind="attrs" v-on="on"><span class="d-none d-sm-flex">LOG IN</span><v-icon class="d-flex d-sm-none" small>fas fa-sign-in-alt</v-icon></v-btn>
                            </template>
                            <v-card tile color="secondary">
                                <v-container>
                                    <button :disabled="loginForm.loading" @click="loginSteam()"><img src="../assets/signinthroughsteam.png" /></button>
                                    <v-divider class="mb-4" />
                                    <v-form ref="loginform" v-model="loginForm.valid" v-on:submit.prevent="() => { }">
                                        <v-row dense>
                                            <v-text-field dense v-model="loginForm.username" :rules="validate.username" label="Username" placeholder=" " required :error="loginForm.error" :loading="loginForm.loading" :disabled="loginForm.loading" />
                                        </v-row>
                                        <v-row dense>
                                            <v-text-field dense v-model="loginForm.password" :rules="validate.password" label="Password" placeholder=" " required :error="loginForm.error" :loading="loginForm.loading" :disabled="loginForm.loading" type="password" />
                                        </v-row>
                                        <v-btn dense color="accent" type="submit" tile block class="text-capitalize" :disabled="!loginForm.valid" :loading="loginForm.loading" @click="login()">Login</v-btn>
                                    </v-form>
                                </v-container>
                            </v-card>
                        </v-menu>
                    </v-row>
                    <v-row no-gutters justify="end" class="loginbar" v-if="$auth.loggedin">
                        <v-menu transition="slide-y-transition" bottom left offset-y tile v-model="userMenu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small text tile v-bind="attrs" v-on="on">
                                    <span class="d-none d-sm-flex">{{$auth.displayname != null && $auth.displayname != '' ? $auth.displayname : ($auth.username != $auth.steamid ? $auth.username : `user #${$auth.id}`)}}</span>
                                    <v-avatar size="32" right :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''">
                                        <v-img v-if="$auth.image == null" src="../assets/noavatar.png" />
                                        <axz-img v-else :id="$auth.image" />
                                    </v-avatar>
                                    <!--v-icon small right>{{userMenu ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}}</v-icon-->
                                </v-btn>
                            </template>
                            <v-list class="pa-0" tile>
                                <v-list-item link dense v-for="(item, i) in userMenuItems" :key="i" v-bind="item.link">
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.text}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon small right>{{item.icon}}</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                                <v-list-item link dense @click="logout()">
                                    <v-list-item-content>
                                        <v-list-item-title>Logout</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon small right>fas fa-sign-out-alt</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu transition="slide-y-transition" bottom left offset-y tile v-model="appMenu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small text tile v-bind="attrs" v-on="on">
                                    <span class="d-none d-sm-flex">{{activeApp.text}}</span>
                                    <v-icon :small="$vuetify.breakpoint.smAndUp" :right="$vuetify.breakpoint.smAndUp">{{appMenu ? activeApp.icon : 'fas fa-th'}}</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="pa-0" tile>
                                <v-list-item link dense v-for="(item, i) in otherApps" :key="i" :href="item.href">
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.text}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon small right>{{item.icon}}</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters class="navbar">
                <v-col cols="3" sm="2">
                    <v-img id="logo" :src="logo != null ? require(`@/assets/${logo}`) : null" contain :position="($vuetify.breakpoint.smAndUp ? '18px' : 'center') + ' center'" />
                </v-col>
                <v-col cols="auto" >
                    <slot name="subheader" />
                </v-col>
                
            </v-row>
        </div>
    </v-app-bar>
</template>

<script>

export default {
    props: [ "title", "logo", "container" ],
    data: function() {
        return {
            loggedin: false,
            validate: {
                username: [
                    v => !!v,
                    v => /[A-Za-z0-9]{3,}/.test(v),
                ],
                password: [
                    v => !!v,
                    v => /^(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+)(?=.*[-_!?$%&+*#~.,]+).{10,}$/.test(v),
                ],
                password2: [
                    v => v == this.signupForm.password,
                ],
                token: [
                    v => !!v && v.length == 64
                ],
            },
            loginForm: {
                show: false,
                valid: false,
                loading: false,
                error: false,
                username: null,
                password: null,
            },
            tokenForm: {
                valid: false,
                loading: false,
                error: false,
            },
            signupForm: {
                show: false,
                valid: false,
                tokenValid: false,
                loading: false,
                error: false,
                token: null,
                username: null,
                password: null,
                password2: null,
            },
            iconMenu: [
                { icon: "fab fa-teamspeak", link: { href: "ts3server://ts.axxez.de" } },
                { icon: "fab fa-discord", link: { href: "https://discord.gg/2AH7GZc", target: "_blank" } },
                { icon: "fab fa-twitter", link: { href: "https://twitter.com/axxezEU", target: "_blank" } },
                { icon: "fab fa-instagram", link: { href: "https://www.instagram.com/axxez.eu", target: "_blank" } },
                { icon: "fab fa-youtube", link: { href: "https://www.youtube.com/channel/UCyW_O5037fWZUVPvIb5wuWg", target: "_blank" } },
            ],
            userMenu: false,
            userMenuItems: [
                { text: "Einstellungen", icon: "fas fa-cog", link: { href: "https://dashboard.axxez.eu/#/settings/account" } }
            ],
            appMenu: false,
            appMenuItems: [
                { text: "Homepage", icon: "fas fa-home", href: "https://axxez.eu" },
                { text: "Dashboard", icon: "fas fa-th-large", href: "https://dashboard.axxez.eu" },
                //{ text: "Liga", icon: "fas fa-trophy", href: "https://league.axxez.eu" }
            ]
        }
    },
    computed: {
        activeApp: function(){
            var app = this.appMenuItems.filter(app => app.href == window.location.origin);
            //console.log(app);
            if(app.length > 0)
                return app[0];
            else 
                return { text: "Debug", icon: "fas fa-bug", href: window.location.origin };
        },
        otherApps: function(){
            return this.appMenuItems.filter(app => app.href != this.activeApp.href);
        }
    },
    watch: {
        'tokenForm.valid' () {
            console.log("tokenForm change");
            // double check form validation :/
            if(this.tokenForm.valid && this.$refs.tokenForm.validate()) { //validate token
                this.tokenForm.loading = true;
                this.call('auth', 'validate', { token: this.signupForm.token }).then(res => {
                    console.log("got res");
                    console.log(res);
                    
                    if(res.success){
                        this.signupForm.tokenValid = true;
                    }
                    else {
                        this.signupForm.token = null;
                        this.triggerTokenError();
                    }
                    this.tokenForm.loading = false;
                });
            }
        },
        '$auth.initialized'() {
            if(this.$auth.initialized && !this.loggedin && this.$route.query.token){
                this.signupForm.token = this.$route.query.token;
                this.signupForm.show = true;
            }
        },
        '$route.query'() {
            if(this.$auth.initialized && !this.loggedin && this.$route.query.token){
                this.signupForm.token = this.$route.query.token;
                this.signupForm.show = true;
            }
        }
    },
    methods: {
        login(){
            this.loginForm.loading = true;
            this.$auth.hash(this.loginForm.username.toLowerCase() + this.loginForm.password).then(hash => {
                this.call('auth', 'login', {
                    hash: hash,
                }).then(res => {
                    console.log(res);
                    if(res.success)
                    {
                        this.$auth.loggedin = true; //needed? status set this
                        this.loginForm.show = false;
                        
                        this.$auth.status();
                    }
                    else {
                        //this.loginForm.error = res.msg;
                        this.triggerLoginError();
                    }
                    this.loginForm.loading = false;
                });
            });
        },
        loginSteam(){
            this.loginForm.loading = true;
            this.call('auth', 'getSteamOpenId').then(res => {
                console.log("got openid url");
                console.log(res);

                if(res.success)
                {
                    //show steam modal fist
                    var steamopenid = res.data;
                    this.popup(steamopenid).then(() => {
                        //try login
                        this.call('auth', 'loginsteam').then(res => {
                            console.log("steam login");
                            console.log(res);

                            if(res.success)
                            {
                                this.$auth.loggedin = true; //needed? status set this
                                this.loginForm.show = false;
                                
                                this.$auth.status();
                            }
                            else {
                                //this.loginForm.error = res.msg;
                                this.triggerLoginError();
                            }

                            this.loginForm.loading = false;
                        });
                    }).catch(() => {
                        //console.log("Popup rejected");
                        //todo maybe message with allow popups!?
                        //this.loginForm.error = res.msg;
                        this.triggerLoginError();
                        this.loginForm.loading = false;

                        this.error('Steam popup rejected', 'login');
                    });
                }
                else { //cant get steam openid url
                    //this.loginForm.error = res.msg;
                    this.triggerLoginError();
                    this.loginForm.loading = false;
                }
            });
        },
        logout(){
            //this.$auth.loggedin = false;
            //todo: show loader
            this.call('auth', 'logout').then(res => {
                console.log(res);
                if(res.success)
                {
                    this.$auth.loggedin = false;
                }
                else {
                    //logout error!?!? LUL?
                }
            });
        },
        clearToken(){
            this.signupForm.token = null;
            this.signupForm.tokenValid = false;
        },
        signup(){
            this.signupForm.loading = true;
            this.call('auth', 'register', {
                token: this.signupForm.token,
                username: this.signupForm.username,
                password: this.signupForm.password,
            }).then(res => {
                console.log(res);
                if(res.success){
                    //registered

                    //clearForm
                    this.signupForm.username = null;
                    this.signupForm.password = null;

                    this.clearToken();
                    this.signupForm.show = false;

                    this.$auth.status();
                }
                else {
                    //this.signupForm.error = res.msg;
                    this.triggerSignupError();
                }
                this.signupForm.loading = false;
            });
        },
        signupSteam(){
            this.signupForm.loading = true;
            this.call('auth', 'getSteamOpenId').then(res => {
                console.log("got openid url");
                console.log(res);

                if(res.success)
                {
                    //show steam modal fist
                    var steamopenid = res.data;
                    this.popup(steamopenid).then(() => {
                        //try register
                        this.call('auth', 'registersteam', {
                            token: this.signupForm.token
                        }).then(res => {
                            console.log(res);
                            if(res.success){
                                //registered

                                this.clearToken();
                                this.signupForm.show = false;

                                this.$auth.status();
                            }
                            else {
                                //this.signupForm.error = res.msg;
                                this.triggerSignupError();
                            }
                            this.signupForm.loading = false;
                        });
                    }).catch(() => {
                        //console.log("Popup rejected");
                        //todo maybe message with allow popups!?
                        //this.signupForm.error = res.msg;
                        this.triggerSignupError();
                        this.signupForm.loading = false;

                        this.error('Steam popup rejected', 'login');
                    });
                }
                else { //cant get steam openid url
                    //this.signupForm.error = res.msg;
                    this.triggerSignupError();
                    this.signupForm.loading = false;
                }
            });
        },
        triggerLoginError(){
            this.loginForm.error = true;
            setTimeout(() => { this.loginForm.error = false; }, 500);
        },
        triggerTokenError(){
            this.tokenForm.error = true;
            setTimeout(() => { this.tokenForm.error = false; }, 500);
        },
        triggerSignupError(){
            this.signupForm.error = true;
            setTimeout(() => { this.signupForm.error = false; }, 500);
        },
    },
    components: {
    },
    mounted() {
        console.log("header mounted");
        if(this.$auth.initialized && !this.loggedin && this.$route.query.token){
            console.log(this.$route.query);
            this.signupForm.token = this.$route.query.token;
            this.signupForm.show = true;
        }
    }
}
</script>

<style scoped>
#bar {
    width: 100%;
}

.header i:hover {
    color: var(--v-accent-base);
}

.header .topbar {
    color: var(--color-nav-topfont);
    border-bottom: 1px solid #525252;
    height: 48px;
    line-height: 48px;
}

.header .topbar .v-btn--tile {
    border-bottom: 1px solid #525252;
}

.header .topbar .v-btn--tile:hover {
    border-bottom: 1px solid var(--v-accent-base);
}

.header .loginbar {
    height: 48px;
}

.header .navbar {
    height: 62px;
    line-height: 62px;
}

#logo {
    height: 56px;
    max-width: 220px;
    top: 3px;
}
</style>

<style>
.header .v-toolbar__content {
    background-color: var(--v-menu-base);
    padding: 0;
    width: 100%;
}

.header .v-btn--tile {
    /*padding: 0px 18px;*/
    height: 100% !important;
    /*position: relative;*/
}

.header .v-btn--tile:hover {
    color: var(--v-accent-base);
    background-color: #0F0F0F;
    z-index: 99;
}

.header .v-btn--tile.v-btn--active {
    background-color: #1B1B1B;
    cursor: default;
}

.header .v-btn--tile.v-btn--active:hover {
    color: #fff;
}



.header .navbar .v-btn--tile {
    border-top: 1px solid #525252;
    top: -2px;
}

.header .navbar .v-btn--tile:hover {
    border-top: 1px solid var(--v-accent-base);
}

.header .navbar .v-btn--tile.v-btn--active {
    border-top: 1px solid var(--v-accent-base);
}



.header .v-btn--tile.v-btn--active::before {
    background-color: transparent;
}

/*.header .btn *{
    line-height: inherit !important;
}*/
</style>