// plugins/database/teams.js
export default {
    install: (app, $db) => {
        $db.teams = {
            data: [],
            public: [],
            publicloaded: 0,
        }
    
        $db.teams.load = () => {
            return app.prototype.$db.get('teams', 'getAll').then(data => $db.teams.data = data == null ? [] : data);
        }

        $db.teams.loadPublic = () => {
            if($db.teams.publicloaded < Date.now() - 1800000){ //30 min
                $db.teams.publicloaded = Date.now();
                return app.prototype.$db.get('teams', 'getPublic').then(data => $db.teams.public = data == null ? [] : data);
            }
        }

        $db.teams.create = (title, filename, image, file) => {
            return app.prototype.$db.get('media', 'create', {
                title: title,
                filename: filename,
                image: image,
                file: file,
            });
        }

        $db.teams.update = (ID, title, filename, image, file) => {
            return app.prototype.$db.get('media', 'update', {
                ID: ID,
                title: title,
                filename: filename,
                image: image,
                file: file,
            });
        }

        $db.teams.publish = (ID) => {
            return app.prototype.call('media', 'publish', {
                ID: ID,
            });
        }

        $db.teams.unpublish = (ID) => {
            return app.prototype.call('media', 'unpublish', {
                ID: ID,
            });
        }
    }
  }