<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-avatar v-if="isImage" left size="64" class="my-auto mr-2" rounded>
                <v-img :src="image_preview" contain />
            </v-avatar>
            <v-icon v-else left>fas fa-file</v-icon>
            <v-file-input v-model="data" :label="label" :rules="rules" :accept="acceptedTypes" :placeholder="placeholder" prepend-icon="" :required="required" :loading="loading" :disabled="loading || disabled" :clearable="newFile" show-size>
            <!-- || (clearable != null && clearable) -->

            </v-file-input>
            <slot />
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: [ "value", "label", "rules", "placeholder", "required", "accept", "disabled", "clearable" ],
        data () {
            return {
                loading: false,
                initialValue: null,
                data: null,
                file: null,
                image: null,
            }
        },
        computed: {
            newFile() {
                return this.data != null && this.data.lastModified != null;
            },
            isImage(){
                return this.data != null && this.data.type.startsWith('image/');
            },
            image_preview () {
                if(typeof this.value == 'object')
                    return URL.createObjectURL(this.value);
                else if(this.image != null)
                    return this.image;
                else return null;
            },
            acceptedTypes() {
                return this.accept != null ? this.accept : '*';
            }
        },
        methods: {
            defaultValue(){
                //console.log("defaultValue()");
                if(this.value != null){
                    if(typeof this.value == 'string'){
                        this.loading = true;
                        this.initialValue = this.value;
                        this.$db.files.get(this.value).then(file => {
                            this.file = file;
                            this.data = { size: this.file.size, name: this.file.type, type: this.file.type };
                            if(file.type.startsWith("image/")){
                                this.$db.files.getImage(this.value).then(image => {
                                    this.image = image;
                                    this.loading = false;
                                });
                            }
                            else this.loading = false;
                        });
                    }
                }
                else {
                    //console.log("value set to null");
                    this.data = null;
                    this.file = null;
                    this.image = null;
                    this.initialValue = null;
                }
            }
        },
        watch: {
            value () {
                this.defaultValue();
            },
            data (value) {
                //console.log("data changed...");
                //console.log(`data: ${this.data} value: ${value}`);
                if(value == null){
                    //console.log("data reset...");
                    
                    this.$emit('input', this.initialValue);
                    if(this.file != null)
                        this.data = { size: this.file.size, type: this.file.type };
                }
                else if(value.lastModified != null){
                    //console.log("data new file...");
                    value.identifier = this.initialValue;
                    this.$emit('input', value);
                }
            }
        },
        mounted () {
            this.defaultValue();
        }
    }
</script>

<style scoped>

</style>