<template>
    <div class="banner d-none d-md-block">
        <div class="content">
            <v-row align="center" justify="space-around" noselect no-gutters>
                <v-col cols="auto">
                    <v-img src="@/assets/TS_Inline_Light_512.png" width="205px" />
                </v-col>
                <v-col cols="auto">
                    <span class="text-h5 font-weight-regular text-uppercase">
                        join our community teamspeak
                        <span class="accent--text">now!</span>
                    </span>
                </v-col>
                <v-col cols="auto">
                    <Button class="ml-5" text="connect" :link="{ href: 'ts3server://ts.axxez.de' }" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner'
}
</script>

<style scoped>
.banner {
    background-color: var(--color-box);
    width: 100%;
    height: 174px;
    line-height: 174px;
}
</style>