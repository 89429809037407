// plugins/database/media.js
export default {
    install: (app, $db) => {
        $db.media = {
            data: [],
            public: [],
            publicloaded: 0,
        }
    
        $db.media.load = () => {
            return app.prototype.$db.get('media', 'getAll').then(data => $db.media.data = data == null ? [] : data);
        }

        $db.media.loadPublic = () => {
            if($db.media.publicloaded < Date.now() - 1800000){ //30 min
                $db.media.publicloaded = Date.now();
                return app.prototype.$db.get('media', 'getPublic').then(data => $db.media.public = data == null ? [] : data);
            }
            
        }

        $db.media.download = (ID) => {
            return `${app.prototype.api_url('media', 'download')}?id=${ID}`;
        }

        $db.media.create = (title, filename, image, file) => {
            return app.prototype.$db.get('media', 'create', {
                title: title,
                filename: filename,
                image: image,
                file: file,
            });
        }

        $db.media.update = (ID, title, filename, image, file) => {
            return app.prototype.$db.get('media', 'update', {
                ID: ID,
                title: title,
                filename: filename,
                image: image,
                file: file,
            });
        }

        $db.media.publish = (ID) => {
            return app.prototype.call('media', 'publish', {
                ID: ID,
            });
        }

        $db.media.unpublish = (ID) => {
            return app.prototype.call('media', 'unpublish', {
                ID: ID,
            });
        }
    }
  }