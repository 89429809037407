// plugins/database/games.js
export default {
    install: (app, $db) => {
        $db.games = {
            data: [] 
        }
    
        $db.games.load = () => {
            return app.prototype.$db.get('games', 'get').then(data => $db.games.data = data == null ? [] : data);
        }

        /*$db.media.loadPublic = () => {
            return app.prototype.$db.get('media', 'getPublic').then(data => $db.media.data = data == null ? [] : data);
        }*/

        /*
        $db.media.download = (identifier, filename) => {
            return $db.files.download(identifier, filename);
        }
        */

        $db.games.create = (name, short, image, banner) => {
            return app.prototype.$db.get('games', 'create', {
                name: name,
                short: short,
                image: image,
                banner: banner,
            });
        }

        $db.games.update = (ID, name, short, image, banner) => {
            return app.prototype.$db.get('games', 'update', {
                ID: ID,
                name: name,
                short: short,
                image: image,
                banner: banner,
            });
        }
    }
  }